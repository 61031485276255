import { useMemo } from "react";
import {
  Field,
  ErrorMessage,
  FastField,
  FormikContextType,
  FieldInputProps,
  FieldMetaProps,
} from "formik";
import TextError from "./TextError";
import { FormInputLabel } from "./FormInputLabel";
import { classNames } from "primereact/utils";
import { InputNumber, InputNumberProps } from "primereact/inputnumber";
import * as Yup from "yup";

export interface FormikInputNumberProps extends InputNumberProps {
  label: string;
  name: string;
  min?: number;
  max?: number;
  validationSchema?: Yup.ObjectSchema<any, Yup.AnyObject, any, "">;
  isIndependent?: boolean;
  isRequired?: boolean;
}

function FormikInputNumber({
  label,
  name,
  min = -Infinity,
  max = +Infinity,
  validationSchema,
  isIndependent = false,
  isRequired = undefined,
  ...rest
}: FormikInputNumberProps) {
  const Component = useMemo(
    () => (isIndependent ? FastField : Field),
    [isIndependent]
  );

  return (
    <>
      <FormInputLabel
        nameFor={name}
        validationSchema={validationSchema}
        forceIsRequired={isRequired}
      >
        {label}
      </FormInputLabel>
      <Component name={name}>
        {({
          form,
          field,
          meta,
        }: {
          form: FormikContextType<any>;
          field: FieldInputProps<number>;
          meta: FieldMetaProps<number>;
        }) => {
          return (
            <InputNumber
              id={name}
              className={classNames({
                "w-full": true,
                "p-invalid": meta.error && meta.touched,
              })}
              min={min}
              max={max}
              placeholder={label}
              value={field.value}
              onBlur={(e) => form?.setFieldTouched(name, true)}
              onChange={(e) => {
                form.setFieldValue(name, e.value);
              }}
              {...rest}
            />
          );
        }}
      </Component>
      <ErrorMessage
        component={TextError}
        name={name}
      />
    </>
  );
}

export default FormikInputNumber;
